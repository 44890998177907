import React from "react"
import MuuriGrid from 'react-muuri'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import { Select, MenuItem, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core'
import 'web-animations-js/web-animations.min';
import SEO from "../components/seo"

class StoryWallPage extends React.Component {
  constructor () {
    super();
    this.state = {
      checked: [],
    }
    this.setChecked = this.setChecked.bind(this)
    this.filter = this.filter.bind(this)
    this.regions = ['hk', 'jp', 'sg', 'tw']
    this.category = ['Running', 'Hiking', 'Surfing', 'Camping', 'Charity']
  }
  componentDidMount () {
    const self = this
    this.grid = new MuuriGrid({
      node: this.gridElement,
      defaultOptions: {
        items: this.generateElements(self.gridElement),
        layout: {
          fillGaps: true,
          alignLeft: true,
          alignTop: true,
          rounding: true
        },
        layoutOnResize: 100,
        layoutOnInit: true,
        layoutDuration: 300,
        layoutEasing: 'ease',
      }
    });
    // An example of how to use `getEvent()` to make `synchronize()` update the grid.
    this.grid.getEvent('dragEnd');
    // window.addEventListener("resize", this.updateLayout.bind(this));
    // window.addEventListener('load', function () {
    //   self.grid.getMethod('refreshItems').layout();
    // });
  }
  generateElements(gridElement) {
    const amount = gridElement.childElementCount
    var ret = [];
    var numbers = [1, 1, 1, 1, 2, 2, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2, 1, 1, 2, 1, 1, 1, 1, 1]
    const winWidth = window.innerWidth

    for (var i = 0; i < amount; i++) {
      var number = numbers[i]
      var width = "w" + number;
      var height = "h" + number;
      var itemElem = gridElement.children[i];
      var small = Math.floor(winWidth / 2 - 15);
      var large = Math.floor(winWidth * 1/6 - 49/3);
      if ( winWidth <  769) {
        if (number === 1) {
          itemElem.style.width = small + 'px'
          itemElem.style.height = small + 'px'
        } else {
          itemElem.style.width = (small * 2 + 10) + 'px'
          itemElem.style.height = (small * 2 + 10) + 'px'
        }
      } else {
        if (number === 1) {
          itemElem.style.width = large + 'px'
          itemElem.style.height = large + 'px'
        } else {
          itemElem.style.width = (large * 2 + 10) + 'px'
          itemElem.style.height = (large * 2 + 10) + 'px'
        }
      }
      console.log(winWidth)
      itemElem.classList.add("item", height, width);
      ret.push(itemElem);
    }
    // self.grid.refreshItems(ret).layout()
    return ret;
  }
  componentWillUnmount () {
    this.grid.getMethod('destroy'); // Required: Destroy the grid when the component is unmounted.
    // window.removeEventListener("resize", this.grid.getMethod('refreshItems'));
    // window.removeEventListener("resize", this.updateLayout.bind(this));
  }
  updateLayout() {
    this.grid.getMethod('refreshItems')
  }
  setChecked(index) {
    const newChecked = this.state.checked;
    newChecked[index] = !newChecked[index];
    this.setState({
      checked: newChecked
    })
  }
  filter(index) {
    this.setChecked(index)
    const regions = this.regions;
    const value = regions[index]
    this.grid.getMethod('filter', (item) => {
      const element = item.getElement();
      const elementRegion = element ? element.getAttribute('data-region') : ''
      const activeRegions = []
      this.state.checked.forEach((isTrue, i) => {
        if (isTrue) {
          activeRegions.push(regions[i])
        }
      })
      const isFilterMatch = !activeRegions.length ? true : activeRegions.includes(elementRegion)
      return isFilterMatch;
    });
  }
  render() {
    const { data } = this.props
    const posts = data.allMdx.edges
    return (
      <div className="storywallPage">
        <SEO title="Story wall" />
        {/* <FormGroup row>
          {this.regions.map((region, i) => {
            return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox onChange={() => this.filter(i)} value={region} />
              }
              label={region}
            />)
          })}
        </FormGroup> */}
        <div ref={gridElement => this.gridElement = gridElement}>
        { posts.map(({ node, index }) => {
          const title = node.frontmatter.title || node.fields.slug
          const fluid = node.frontmatter.thumbImage.childImageSharp.fluid
          return (
            <Link
              data-region={node.frontmatter.region}
              key={node.fields.slug}
              style={{ boxShadow: `none` }}
              to={node.fields.slug}>
              <Img alt={title} fluid={fluid} />
              {/* <div className="item-content">
                {title}
              </div> */}
            </Link>
          )
        })}
        </div>
      </div>
    )
  }
}

export default StoryWallPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: {parent: {internal: {mediaType: {eq: "text/mdx"}}}} sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            region
            thumbImage {
              childImageSharp {
                fluid(maxWidth: 2400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
